(function() {
  var paymentsUiHost = 'https://payments.lh360.com';

  var create = function(opts) {
    var widgetUrl = paymentsUiHost + '/accounts/' + opts.accountId + '/payment';

    if (location.protocol != 'https:') {
      var button = document.createElement('a');

      button.href = widgetUrl;
      button.target = '_blank';
      button.style.textDecoration = 'none';
      button.style.border = '1px solid #838D93';
      button.style.backgroundColor = '#838D93';
      button.style.display = 'block';
      button.style.width = '340px';
      button.style.height = '70px';

      var img = document.createElement('img');
      img.src = paymentsUiHost + '/assets/money.png';

      img.style.height = '40px';
      img.style.border = 'none';
      img.style.width = '55px';
      img.style.padding = '20px 9px 10px 11px';
      img.style.backgroundColor = 'white';
      img.style.verticalAlign = 'middle';

      var buttonText = document.createElement('span');
      buttonText.innerHTML = 'Send a Secure Payment';

      buttonText.style.color = 'white';
      buttonText.style.fontSize = '20px';
      buttonText.style.fontWeight = '100';
      buttonText.style.fontFamily = 'sans-serif';
      buttonText.style.display = 'inline-block';
      buttonText.style.verticalAlign = 'middle';
      buttonText.style.paddingLeft = '25px';

      button.appendChild(img);
      button.appendChild(buttonText);

      return button;
    }

    var iframe = document.createElement('iframe');
    widgetUrl = widgetUrl + '?amount=' + opts.amount;
    if (opts.paymentMetadata !== undefined) {
      for (var key in opts.paymentMetadata) {
        if (opts.paymentMetadata.hasOwnProperty(key)) {
          widgetUrl += '&' + 'meta.' + key + '=' + opts.paymentMetadata[key];
        }
      }
    }
    iframe.setAttribute('src', widgetUrl);
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.setAttribute('frameborder', '0');
    return iframe;
  };

  var Payment = function(options) {
    this.options = options;

    var payment = this;
    var target = options.target;
    var embedContainer = options.embedContainer;
    var widgetUrl = paymentsUiHost + '/accounts/' + options.accountId + '/payment';

    /* am: updating the docs: include the script at the end of your body */
    if (target !== undefined && embedContainer !== undefined) {
      throw new Error('Cannot use both `target` and `embedContainer` at the same time ');
    }
    else {
      if (options.embedContainer !== undefined) {
        payment.open(options.callbacks);
      }
      else {
        var targetElement = document.getElementById(target);
        targetElement.onclick = function() {
          if (location.protocol != 'https:') {
            window.open(widgetUrl);
          }
          else {
            payment.open(options.callbacks);
          }
        };
      }
    }
  };

  Payment.initialize = function(options) {
    return new Payment(options);
  };

  Payment.prototype.open = function(callbacks) {
    this.iframe = create(this.options);
    if (this.options.embedContainer) {
      var elm = this.options.embedContainer;
      var placeHolder = elm.nodeName ? elm : document.getElementById(elm);
      placeHolder.appendChild(this.iframe);
    }
    else if (this.options.target) {

      var backdrop = document.createElement('div');
      backdrop.setAttribute('id', 'js-iframe-backdrop');
      backdrop.style.width = '100%';
      backdrop.style.height = '100%';
      backdrop.style.position = 'fixed';
      backdrop.style.top = '0';
      backdrop.style.left = '0';
      backdrop.style.backgroundColor = 'rgba(0,0,0,0.7)';

      var iframeInner = document.createElement('div');
      iframeInner.style.width = '500px';
      iframeInner.style.height = '100%';
      iframeInner.style.margin = '0 auto';

      var closeLink = document.createElement('a');
      closeLink.setAttribute('href', '#');
      closeLink.innerHTML = '&times;';
      closeLink.style.color = '#fff';
      closeLink.style.fontSize = '24px';
      closeLink.style.textDecoration = 'none';
      closeLink.style.float = 'right';
      closeLink.style.position = 'relative';
      closeLink.style.right = '-4px';
      closeLink.style.top = '16px';


      iframeInner.appendChild(closeLink);
      backdrop.appendChild(iframeInner);
      iframeInner.appendChild(this.iframe);
      closeLink.addEventListener('click', function () {
        /* am: get body and remove the iframe backdrop */
        var body = document.getElementsByTagName('body')[0];
        var toRemove = document.getElementById('js-iframe-backdrop');
        body.removeChild(toRemove);
      });

      document.body.appendChild(backdrop);
    }

    window.addEventListener('message', function(event) {
      if (event.origin === paymentsUiHost) {
        var eventType = event.data.status;
        if (eventType === 'ERROR' && callbacks.error != undefined) {
          callbacks.error(event);
        }
        else if (eventType === 'CAPTURE' && callbacks.capture != undefined) {
          callbacks.capture(event);
        }
      }
      return;
    }, false);
  };

  Payment.prototype.close = function() {
    this.iframe.parentNode.removeChild(this.iframe);
  };

  window.Payment = Payment;
})();